body.home{
    h2{ margin-bottom: 50px;}
    .text{ margin-bottom: 90px;}
}


@media(max-width:767px) {
    body.home{
        h2{ margin-bottom: 40px;}
        .text{ margin-bottom: 40px;}
    }
}