/*
* image
*/
.image{
    float: left;
    width: 40%;
    img{
        object-fit:  cover;
        width: 100%;
    }
}

@media(max-width:767px) {
    .image{
        width: 100%;
        float: none;        
        height: auto !important;
        img{height: auto !important; }
    }
}

@media(max-width:767px) {
    .image{
        &:before{
            border-bottom: 620px solid rgba(0, 0, 0, 0);
            border-left: 1000px solid #ffffff;
            content: "";
            height: 0;
            left: 0;
            position: absolute;
            top: -65px;
            transform: skewY(-7deg);
            width: 0;
         } 
         overflow: hidden;
         position: relative;       
      }     
}

@media(max-width:575px) {
    .image{
        &:before{
            border-bottom: 500px solid rgba(0, 0, 0, 0);
            border-left: 710px solid #ffffff;
         }        
      }     
}

@media(max-width:480px) {
    .image{
        &:before{
            border-bottom: 400px solid rgba(0, 0, 0, 0);
            border-left: 615px solid #ffffff;
         }       
      }     
}

@media(max-width:420px) {
    .image{
        &:before{
            border-bottom: 400px solid rgba(0, 0, 0, 0);
            border-left: 525px solid #ffffff;
         }       
      }     
}

@media(max-width:375px) {
    .image{
        &:before{
            border-bottom: 340px solid rgba(0, 0, 0, 0);
            border-left: 490px solid #ffffff;
         }       
      }     
}


@media(max-width:360px) {
    .image{
        &:before{
            border-bottom: 365px solid rgba(0, 0, 0, 0);
            border-left: 460px solid #ffffff;
         }       
      }     
}


@media(max-width:350px) {
    .image{
        &:before{
            border-bottom: 290px solid rgba(0, 0, 0, 0);
            border-left: 435px solid #ffffff;          
         }       
      }     
}