/*
* container
*/
#container{
    min-width: 320px;
    overflow-x: hidden;
    position: relative;
}

/*
* header
*/
#header{
    background: rgba(0,0,0,.05);
    height: 85px;
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

/*
* wrapper
*/
.wrapper{
    float: left;
    width: 60%;
    position: relative;
}

/*
* content
*/
#content {
    padding: 120px 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

}

@media(max-width:767px) {
    #content{
        padding: 185px 0 10px;
    }
}