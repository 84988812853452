/*
* contact-us
*/
.contact-us{
    li{
        &:first-child{
            border-left: none;
            padding: 2px 5px 2px 0;
        }
        float: left;
        width: 33.3%;
        border-left: 1px solid #cccccf;
        color: #ff9517;
        padding: 5px 5px 5px 15px;
        a{
            font-weight: 400;
            font-size: rem-calc(16);
            color: #ff9517;
            text-transform: capitalize;
        }
        .btn-outline-primary{ min-width: 140px;}
    }
}

@media(min-width:768px) and  (max-width:991px) {
    .contact-us{
        li{
            &:first-child{ width: 28%;}
                font-size: rem-calc(13);
                width: 34.3%;
                padding: 5px 5px 5px 5px;
            a{font-size: rem-calc(13);}
            .btn-outline-primary{ min-width: auto;}
        }
    }
}


@media(max-width:767px) {
    .contact-us{
        li{
            &:first-child{ padding: 0;}
            float: none;
            margin-bottom: 10px;
            width: 100%;
            padding: 0;
            border-left: none;
        }
    }
}


