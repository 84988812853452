/*
* wrapper
*/
.wrapper{

    .container{
        position: relative;
        width: 600px;        
    }
}


@media(max-width:767px) {
    .wrapper{
        position: relative;
        float: none;
        width: 100%;
        height: auto !important;
        .container{
            width: 100%;
        }
    }
}

/*
* help
*/
.help {
    font-size: rem-calc(18);
    color: #5a5a5a;        
  }

/*
* wrapper
*/
.wrap{
    width: 100%;
}


