body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.group {
  @include clearfix();
}

img {
  max-width: 100%;
}

figure {
  margin: 0px;
}

/*-------------------------------------------------------------
 common code
-------------------------------------------------------------*/

html, body, #container{ height: 100%;}
@media(max-width:767px) {
  html, body, #container{ height: auto;}
}


ul{
  padding: 0;
  margin: 0 0 15px;
    li{
      list-style: none;
    }
}

a{
  &:hover{
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; 
    -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; 
    -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; 
    -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; 
    -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; 
  -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; 
  -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; 
  -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s; 
  -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

hr{ margin: 16px 0 30px; }
@media(max-width:767px) { 
  hr{ margin: 30px 0 30px; }
}


@media(max-width:767px) {
  h1{ font-size: rem-calc(30);}
  h2{ font-size: rem-calc(26);}

}

@media(max-width:767px) {
  .container { padding: 0 20px;}
}


.btn-outline-primary{ padding: 10px;}