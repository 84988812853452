/*
* header
*/
#header{
    &:before{
           background: rgba(0, 0, 0, 0.05);
           content: "";
           height: 85px;
           left: 0;
           position: absolute;
           top: 0;
           width: 100000px;    
    }
    .container{
        position: relative;
        height: 85px;
    }
}


/*
*  logo
*/
.logo{
    position: absolute;
    bottom: -35px;
    left:0;
    padding-left: 15px;
}